import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AdminAppBarToolbar",
})(() => {
  return {
    root: {
      minHeight: 44 + 40,
    },
  };
});

export default useStyles;
