import { commonConstants } from "@/utils/constants";
import { alpha, darken } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<
  void,
  | "selected"
  | "listItemText"
  | "drawerPaper"
  | "drawer"
  | "listItem"
  | "listItemButton"
  | "listContainer"
  | "drawerCollapseOnHover"
>({
  name: "Sidebar",
  uniqId: "qsejAe",
})((theme, _, classes) => {
  return {
    drawer: {
      width: commonConstants.ADMIN_SIDEBAR_WIDTH,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerCollapsed: {
      position: "absolute",
    },
    drawerCollapseOnHover: {
      position: "absolute",
      width: `calc(${commonConstants.ADMIN_SIDEBAR_COLLAPSED_WIDTH}px)`,
      [`& .${classes.drawerPaper}`]: {
        width: `calc(${commonConstants.ADMIN_SIDEBAR_COLLAPSED_WIDTH}px)`,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      [`& .${classes.listItem}.${classes.selected}:before`]: {
        left: `calc(${commonConstants.ADMIN_SIDEBAR_COLLAPSED_WIDTH}px)`,
        transition: theme.transitions.create("left", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      [`& .${classes.listContainer}`]: {
        overflow: "hidden",
      },
    },
    drawerPaper: {
      width: commonConstants.ADMIN_SIDEBAR_WIDTH,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.common.darkNeutral,
    },
    drawerContent: {
      width: commonConstants.ADMIN_SIDEBAR_WIDTH,
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflow: "auto",
    },
    logoContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [`.${classes.drawerCollapseOnHover} &`]: {
        width: commonConstants.ADMIN_SIDEBAR_COLLAPSED_WIDTH,
        display: "flex",
        justifyContent: "center",
      },
    },
    logo: {
      gap: theme.spacing(1),
    },
    logoImage: {
      height: 44,
      fill: theme.palette.common.white,
    },
    logoCollapseImage: {
      width: 44,
      height: 44,
      fill: theme.palette.common.white,
    },
    listContainer: {
      flex: 1,
      overflowX: "hidden",
      overflowY: "auto",
      padding: theme.spacing(3),
    },
    list: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    listItem: {
      display: "block",
      borderRadius: theme.shape.borderRadius,
      overflow: "clip",
      [`&.${classes.selected}`]: {
        backgroundColor: theme.palette.text.secondary,
        [`& .${classes.listItemButton}`]: {
          position: "relative",
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: darken(
              theme.palette.text.secondary,
              theme.palette.action.hoverOpacity
            ),
          },
          [`& .${classes.listItemText}`]: {
            color: theme.palette.secondary.main,
          },
        },
      },
      [`.${classes.drawerCollapseOnHover} &`]: {
        width: 60,
      },
    },
    listItemButton: {
      minHeight: 60,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [`.${classes.drawerCollapseOnHover} &`]: {
        padding: "0 !important",
      },
      "&:hover": {
        [`& .${classes.listItemText}`]: {
          color: theme.palette.secondary.main,
        },
        backgroundColor: darken(
          theme.palette.text.secondary,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    listItemText: {
      textTransform: "capitalize",
      color: theme.palette.text.secondary,
      margin: 0,
      [`.${classes.drawerCollapseOnHover} &`]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    listItemTextCollapsedChar: {
      width: 24,
      height: 24,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    selected: {},
  };
});

export default useStyles;
