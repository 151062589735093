import { bindActionCreators } from "redux";

import { storeAuthAction, storeAuthSelectors } from "@/store";

import AppSelect from "@/components/AppSelect";
import AppSelectMenuItem from "@/components/AppSelectMenuItem";
import AppInputAdornment from "@/components/AppInputAdornment";
import AppTypography from "@/components/AppTypography";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { commonHelpers } from "@/utils/helpers";

const BeautyCenterSelect = () => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const $s_authAction = useMemo(
    () => bindActionCreators(storeAuthAction, dispatch),
    [dispatch]
  );

  const $s_authUserSelectedBeautyCenter = useAppSelector(
    (state) => state.auth.authUserSelectedBeautyCenter
  );
  const $s_authUserBeautyCenters = useAppSelector(
    storeAuthSelectors.selectAuthUserBeautyCenters
  );

  const selectedBeautyCenter = useMemo(() => {
    return (
      $s_authUserBeautyCenters.find(
        (beautyCenter) =>
          beautyCenter.id === $s_authUserSelectedBeautyCenter?.id
      ) ?? null
    );
  }, [$s_authUserBeautyCenters, $s_authUserSelectedBeautyCenter]);

  const handleAuthUserSelectedBeautyCenterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const beautyCenterIdValue = !!event.target.value
      ? Number(event.target.value)
      : null;
    const selectedBeautyCenter = $s_authUserBeautyCenters.find(
      (authUserBeautyCenter) => authUserBeautyCenter.id === beautyCenterIdValue
    );
    $s_authAction.setAuthUserSelectedBeautyCenter(selectedBeautyCenter ?? null);
  };

  return (
    <AppSelect
      bgColor="common.white"
      startAdornment={
        !isMdDown ? (
          <AppInputAdornment position="start">{`${t(
            "center"
          )}:`}</AppInputAdornment>
        ) : undefined
      }
      renderValue={() => (
        <AppTypography noWrap>{selectedBeautyCenter?.name}</AppTypography>
      )}
      value={$s_authUserSelectedBeautyCenter?.id}
      onChange={handleAuthUserSelectedBeautyCenterChange as any}
    >
      {$s_authUserBeautyCenters.map((authUserBeautyCenter) => (
        <AppSelectMenuItem
          key={authUserBeautyCenter.id}
          value={authUserBeautyCenter.id}
        >
          {commonHelpers.getFieldValueByLanguage(
            authUserBeautyCenter,
            "name",
            i18n.language
          )}
        </AppSelectMenuItem>
      ))}
    </AppSelect>
  );
};

export default BeautyCenterSelect;
