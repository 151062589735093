import { bindActionCreators } from "redux";

import { storeCommonAction, storeNotificationSelectors } from "@/store";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import IMediaTextSvg from "@@/public/images/svgs/i-media-text.svg";
import IMediaSvg from "@@/public/images/i-media.svg";

import AppLink from "@/components/AppLink";
import AdminAppBarToolbar from "@/components/AdminAppBarToolbar";
import AppList from "@/components/AppList";
import AppListItem from "@/components/AppListItem";
import AppListItemText from "@/components/AppListItemText";
import AppDrawer from "@/components/AppDrawer";
import AppListItemButton from "@/components/AppListItemButton";

import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useTranslation } from "next-i18next";
import { useMemo, useState } from "react";

import useStyles from "./Sidebar.styles";
import AppChip from "@/components/AppChip";
import AppBadge from "@/components/AppBadge";

type MenuListProps = {
  sidebarOpened?: boolean;
  sidebarCollapsed?: boolean;
  menus: Partial<(typeof menus)[number]>[];
};

type Menu = {
  name: string;
  path?: string;
  activePath: RegExp;
  permissionName?: string;
};

type UnreadNotificationItemProps = {
  menu: Partial<Menu>;
  sidebarCollapsed?: boolean;
};

const menus: Menu[] = [
  {
    name: "appointmentList",
    path: "/appointments",
    activePath: new RegExp("^/appointments"),
  },
  {
    name: "notifications",
    path: "/notifications",
    activePath: new RegExp("^/notifications"),
  },
  {
    name: "beautyCenterProfile",
    path: "/beauty-center-profile",
    activePath: new RegExp("^/beauty-center-profile"),
  },
  {
    name: "settings",
    path: "/settings",
    activePath: new RegExp("^/settings"),
  },
];

const UnreadNotificationMenuItem = (props: UnreadNotificationItemProps) => {
  const { menu, sidebarCollapsed } = props;

  const $s_publicUnreadNotificationsCount = useAppSelector(
    storeNotificationSelectors.selectPublicUnreadNotificationsCount
  );

  const { t } = useTranslation();

  const { classes } = useStyles();

  return (
    <>
      <AppListItemText
        classes={{ primary: classes.listItemText }}
        primary={
          sidebarCollapsed ? (
            <AppBadge
              badgeContent={2}
              color="secondary"
              textColor="common.darkNeutral"
            >
              <span className={classes.listItemTextCollapsedChar}>
                {t(menu!.name! as any).charAt(0)}
              </span>
            </AppBadge>
          ) : (
            t(menu!.name! as any)
          )
        }
        primaryTypographyProps={{
          variant: "bodySemi16",
        }}
      />
      {!!$s_publicUnreadNotificationsCount && !sidebarCollapsed && (
        <AppChip
          label={
            $s_publicUnreadNotificationsCount >= 100
              ? "+99"
              : $s_publicUnreadNotificationsCount
          }
          color="secondary.main"
          variant="filled"
          size="small"
          borderRadius="rounded"
        />
      )}
    </>
  );
};

const MenuList = (props: MenuListProps) => {
  const { menus, sidebarOpened, sidebarCollapsed } = props;

  const router = useRouter();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const { classes, cx } = useStyles();

  return (
    <AppList className={classes.list} disablePadding>
      {menus.map((menu, menuIndex) => {
        return (
          <AppListItem
            className={cx(classes.listItem, {
              [classes.selected]: !!router.pathname.match(
                menu?.activePath || ""
              ),
            })}
            key={menuIndex}
            disablePadding
          >
            <AppListItemButton
              sx={{
                justifyContent: sidebarOpened ? "initial" : "center",
              }}
              classes={{
                root: classes.listItemButton,
              }}
              disableGutters
              component={menu?.path ? AppLink : "div"}
              href={menu?.path}
              onClick={() => {
                $s_commonAction.setFloatAdminSidebarOpened(false);
              }}
            >
              {menu.name === "notifications" ? (
                <UnreadNotificationMenuItem
                  sidebarCollapsed={sidebarCollapsed}
                  menu={menu}
                />
              ) : (
                <AppListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={
                    sidebarCollapsed ? (
                      <span className={classes.listItemTextCollapsedChar}>
                        {t(menu!.name! as any).charAt(0)}
                      </span>
                    ) : (
                      t(menu!.name! as any)
                    )
                  }
                  primaryTypographyProps={{
                    variant: "bodySemi16",
                  }}
                />
              )}
            </AppListItemButton>
          </AppListItem>
        );
      })}
    </AppList>
  );
};

const Sidebar = () => {
  const [rootHovered, setRootHovered] = useState(false);

  const dispatch = useAppDispatch();

  const $s_commonAction = useMemo(
    () => bindActionCreators(storeCommonAction, dispatch),
    [dispatch]
  );

  const $s_adminSidebarCollapseOpened = useAppSelector(
    (state) => state.common.adminSidebarCollapseOpened
  );
  const $s_floatAdminSidebarOpened = useAppSelector(
    (state) => state.common.floatAdminSidebarOpened
  );

  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const isCollapsedOnHover = useMemo(
    () => $s_adminSidebarCollapseOpened && !isMdDown && !rootHovered,
    [$s_adminSidebarCollapseOpened, isMdDown, rootHovered]
  );

  const handleSidebarClose = () => {
    $s_commonAction.setFloatAdminSidebarOpened(!$s_floatAdminSidebarOpened);
  };

  return (
    <AppDrawer
      className={cx(classes.drawer, {
        [classes.drawerCollapseOnHover]: isCollapsedOnHover,
        [classes.drawerCollapsed]: $s_adminSidebarCollapseOpened,
      })}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      variant={isMdDown ? "temporary" : "permanent"}
      open={$s_floatAdminSidebarOpened}
      onClose={handleSidebarClose}
      onMouseEnter={() => setRootHovered(true)}
      onMouseLeave={() => setRootHovered(false)}
    >
      <div className={classes.drawerContent}>
        <AdminAppBarToolbar disableGutters className={classes.logo}>
          <div className={classes.logoContainer}>
            {isCollapsedOnHover ? (
              <IMediaTextSvg className={classes.logoCollapseImage} />
            ) : (
              <IMediaSvg className={classes.logoImage} />
            )}
          </div>
        </AdminAppBarToolbar>
        <div className={classes.listContainer}>
          <MenuList
            menus={menus}
            sidebarCollapsed={isCollapsedOnHover}
            sidebarOpened={$s_floatAdminSidebarOpened}
          />
        </div>
      </div>
    </AppDrawer>
  );
};

export default Sidebar;
