import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AppMenu",
  uniqId: "7vgi7B",
})(() => {
  return {
    paper: {
      boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.12)",
    },
  };
});

export default useStyles;
